.wrapper {
  width: 20rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-weight: bold;
    font-size: var(--font-size-xxxl);
    background: -webkit-linear-gradient(330deg, #49B8ED 100%, #3E7BE5 100%, #CEEAFF, #242424);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h5 {
    margin: var(--space-18) 0 var(--space-12);
    font-size: var(--font-size-xl);
    color: var(--color-main);
  }

  p {
    font-size: var(--font-size-r);
    color: var(--color-white);
    text-align: center;
  }
}