.wrapper {
  padding-top: var(--space-16);
  padding-bottom: var(--space-19);

  h1 {
    font-size: var(--font-size-xxxl);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    font-family: 'Roboto Condensed', sans-serif;
    background: radial-gradient(50% 50%, #49B8ED, #3E7BE5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media screen and(max-width: 1400px) {
  .wrapper {
    padding-bottom: 0;
  }
}