.containerParallax {
  background: 0;
  display: block;
  margin-top: var(--space-17);
  overflow: visible;
  padding: 0;
  width: auto;
  z-index: 1;
  vertical-align: baseline;

  .itemImage {
    position: relative;
    background-repeat: no-repeat;
    padding: 0;
    background-attachment: fixed;
    background-size: 34rem;
    background-position: 80%;
    //clear: both;
    //display: block;

    .systemContent {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      left: 0;
      bottom: auto;
      padding: 2% 0;
      width: 45%;
      height: 28rem;
      right: 5%;

      .image {
        display: none;
      }

      .title {
        span {
          transform: rotate(-90deg);
          -webkit-transform: rotate(-90deg);
          -moz-transform: rotate(-90deg);
          font-size: var(--font-size-xxxxxxl);
          color: var(--color-opacity);
          font-weight: var(--font-weight-bold);
          margin-right: var(--space-9);
        }
        h3 {
          display: none;
          font-size: var(--font-size-xl);
          text-transform: uppercase;
          color: var(--color-blue-2);
          margin-bottom: var(--space-9);
        }
      }

      .description {
        text-align: left;

        h3 {
          font-size: var(--font-size-xl);
          text-transform: uppercase;
          color: var(--color-blue-2);
          margin-bottom: var(--space-9);
        }

        p {
          font-size: var(--font-size-r);
          font-weight: normal;
          margin-bottom: var(--space-5);
        }
      }
    }
  }
}

@media screen and(max-width: 1500px) {
  .containerParallax {
    .itemImage {
      background-size: 28rem;
    }
  }
}

@media screen and(max-width: 1300px) {
  .containerParallax {
    .itemImage {
      background-size: 0 0;
      background-image: none;

      .systemContent {
        width: 100%;
        //height: 20rem;
        height: 100%;
        display: flex;
        flex-direction: column;

        .image {
          display: block;
          width: 100%;
        }

        .title {
          display: flex;
          align-items: flex-end;
          margin-bottom: var(--space-9);

          span {
            transform: rotate(0);
            font-size: var(--font-size-xxxxl);
            margin-right: var(--space-3);
          }

          h3 {
            display: block;
            margin-bottom: var(--space-2);
          }
        }

        .description {
          h3 {
            display: none;
          }
        }
      }
    }
  }
}