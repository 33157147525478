.wrapper {
  .menu {
    padding-top: var(--space-6);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    h4 {
      color: var(--color-grey);
      text-transform: uppercase;
      font-size: var(--font-size-xl);
      cursor: pointer;
      margin: var(--space-3) var(--space-8);
      font-family: 'Roboto Condensed', sans-serif;

      &:hover {
        color: var(--color-hover);
      }

      &.active {
        color: var(--color-blue-4);
      }
    }
  }

  .systems {
    margin-top: var(--space-12);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: var(--space-5);
    overflow: hidden;

    .system {
      background: var(--color-opacity-3);
      border: 2px solid var(--color-accent-2);
      box-sizing: border-box;
      border-radius: 3px;
      padding: var(--space-2) var(--space-2) var(--space-3);

      h2 {
        font-size: var(--font-size-r);
        color: var(--color-light);
        font-weight: var(--font-weight-semibold);
      }

      p {
        font-size: var(--font-size-m-r);
        padding-top: var(--space-3);
      }
    }
  }
}

@media screen and(max-width: 1400px) {
  .wrapper {
    .systems {
      height: 26rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: var(--space-2);

      .system {
        margin-top: var(--space-9);
        margin-right: var(--space-7);
        height: var(--space-24);
        width: var(--space-28);
      }
    }
  }
}