.wrapper {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(251.45deg, #1D4C7C -9.7%, #253755 21.12%, #253755 73.13%, #4F4149 113.58%), linear-gradient(251.45deg, rgba(0, 147, 255, 0.24) -9.7%, #1D4C7C -9.7%, #253755 21.12%, #253755 73.13%, #4F4149 113.58%);
  padding-top: var(--space-16);

  h1 {
    font-size: var(--font-size-xxxl);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    font-family: 'Roboto Condensed', sans-serif;
    background: radial-gradient(50% 50%, #49B8ED, #3E7BE5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media screen and(max-width: 850px) {
  .wrapper {
    min-height: 100%;
  }
}