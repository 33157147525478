.wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: var(--space-7);
  
  a {
    color: var(--color-blue-5);
    font-size: var(--font-size-l);

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: var(--color-blue-6)
    }
  }

  .links {
    a {
      margin: 0 var(--space-5);
    }

    & .activeLink {
      color: var(--color-blue-6)
    }
  }
}