.wrapper {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-r);

  .left {
    color: var(--color-text);

    img {
      border-radius: var(--button-radius);
      height: var(--space-13);
    }
  }

  .menu {
    a {
      color: var(--color-menu);
      margin: 0 var(--space-4);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .menuMobile {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .wrapper {
    .menu {
      display: none;
    }

    .menuMobile {
      display: block;
    }
  }
}