.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: var(--space-2-5);

  .leanerContainer {
    width: 100%;
    height: var(--space-1);
    background: var(--color-accent-2);
    display: flex;
    justify-content: flex-start;
    border-radius: 4px;

    .leaner {
      height: var(--space-1);
      border-radius: 4px;
      transition-duration: 400ms;

      &.unreliable {
        background: linear-gradient(0deg, #FF431A, #FF431A);
      }

      &.secure {
        background: linear-gradient(95.07deg, #3E7BE5 -3.65%, #49B8ED 92.16%);
      }
    }
  }

  .errors {
    margin-top: var(--space-1);

    span {
      font-size: var(--font-size-m);
      color: var(--color-blue-4);
    }
  }
}