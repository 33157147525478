.button {
  width: 14.3em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-l);
  border-radius: var(--textarea-radius);
  cursor: pointer;
  padding-bottom: 2px;
  border: none;
  outline: none;
}

.round {
  background: var(--main-gradient);
  color: var(--color-main);
  box-shadow: var(--box-shadow);

  &:hover {
    background: linear-gradient(95.8deg, #449DE9 -10.63%, #3E7BE5 110.63%), #49B8ED;
  }

  &:disabled {
    background: linear-gradient(0deg, #3F81E6, #3F81E6), #49B8ED;
    box-shadow: 0 20px 30px rgba(16, 35, 60, 0.2);
    color: rgba(255, 255, 255, 0.7);
      cursor: not-allowed;

  }
}

.shrink {
  width: 12.2rem;
  background: var(--color-main);
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--color-blue-4);

  &:hover {
    border: 3px solid ;
    box-shadow: 0 15px 30px rgba(47, 107, 182, 0.2);
  }
}

.light {
  width: 12.2rem;
  background: var(--color-accent);
  border: 3px solid var(--color-accent-2);
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--color-blue);

  &:hover {
    border: 3px solid var(--color-blue);
  }
}

.success {
  background-color: var(--color-green-2);

  &:hover {
    opacity: 0.9;
  }
}

@media screen and (max-width: 800px) {
  .button {
    /*font-size: var(--font-size-s);*/
    font-size: var(--font-size-r);
  }

  .shrink {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }
}
