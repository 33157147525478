.wrapper {
  width: 100%;
  min-height: 100vh;
  background-image: url("./images/background-main.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    padding: var(--space-8) 0;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url("./images/map.png");

    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        font-size: var(--font-size-xxxxl);
        color: var(--color-title);
        font-weight: var(--font-weight-semibold);
        text-align: left;
      }

      .secondTitle {
        font-size: var(--font-size-xxxxxl);
        color: var(--color-title);
        text-transform: uppercase;
        font-weight: var(--font-weight-semibold);
        text-align: left;
      }

      p {
        margin-top: var(--space-3);
        font-size: var(--font-size-xl);
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        margin-top: var(--space-8);

        .btn {
          margin-right: var(--space-8);
        }
      }
    }
  }
}

@media screen and(max-width: 1500px) {
  .wrapper {
    .content {
      flex-direction: column;

      .left {
          align-items: center;

        .title {
          font-size: var(--font-size-xxxl);
        }

        .secondTitle {
          font-size: var(--font-size-xxxxl);
          text-align: center;
        }
      }

      .right {
        margin-top: var(--space-13);
      }
    }
  }
}

@media screen and(max-width: 600px) {
  .wrapper {
    .content {
      .left {
        .title {
          font-size: var(--font-size-xl);
          text-align: center;
          font-weight: var(--font-weight-bold);
        }

        .secondTitle {
          font-size: var(--font-size-xxl);
          font-weight: var(--font-weight-bold);
        }

        .buttons {
          .btnText {
            display: none;
          }

          .btn {
            position: relative;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }

      }
      .right {
        img {
          width: 90%;
        }
      }
    }
  }
}