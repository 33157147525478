.wrapper {
  background: linear-gradient(251.45deg, rgba(0, 147, 255, 0.24) -9.7%, #1D4C7C -9.7%, #253755 21.12%, #253755 73.13%, #4F4149 113.58%);
  width: 100%;
  min-height: calc(100vh - 80px);

  .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: var(--space-12);
  }
}

@media screen and(max-width: 1000px) {
  .wrapper {
    .content {
      display: flex;
      flex-direction: column;
    }
  }
}