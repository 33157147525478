.wrapper {
  background-image: url("../../pages/MainLayout/images/map.png");
  margin-top: var(--space-17);
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--color-dark-1);
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-semibold);
  }

  .content {
    width: 25rem;
    border-top: 1px solid var(--color-accent-2);
    padding-bottom: var(--space-9);
    margin-top: var(--space-9);

    form {
      display: flex;
      flex-direction: column;
      margin-top: var(--space-4);

      .input {
        margin-top: var(--space-6);
        height: var(--space-13);
        background: var(--color-main);
        border: 1px solid var(--color-opacity-4);
        box-sizing: border-box;
        border-radius: var(--textarea-radius);
        font-size: var(--font-size-r);
        padding-left: var(--space-7);
        color: var(--color-blue-4);
        text-align: center;
        outline: none;
        cursor: pointer;

        &:hover {
          border: 1px solid var(--color-blue-4);
        }

        &:focus {
          border: 1px solid var(--color-blue-4);
        }

        &::placeholder {
          color: var(--color-blue-5);
          text-align: left;
        }

        &:disabled {
          background: #c1cad9;
          border: 1px solid var(--color-blue-4);
        }
      }

      .password {
        width: 100%;
        position: relative;

        .icon {
          cursor: pointer;
          position: absolute;
          top: 55%;
          right: 5%;
        }

        input {
          width: 100%;
        }
      }

      .checkbox {
        position: relative;
        cursor: pointer;
        user-select: none;
        margin-top: var(--space-6);

        label {
          cursor: pointer;
          display: flex;
          align-items: center;

          p {
            color: var(--color-blue-5);
            font-size: var(--font-size-r);
            margin-left: var(--space-7);
            margin-top: var(--space-1);
          }

          &:after {
            content: "";
            position: absolute;
            display: none;
          }

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: var(--space-5);
          width: var(--space-5);
          box-sizing: border-box;
          border: 1px solid var(--color-opacity-4);
          border-radius: var(--textarea-radius);
        }

        label:hover input ~ .checkmark {
          border: 1px solid var(--color-blue-4);
        }

        label input:checked ~ .checkmark {
          background: var(--color-blue);
        }

        label input:checked ~ .checkmark:after {
          display: block;
        }

        label input:checked ~ .checkmark:after {
          content: "✔";
          font-size: var(--font-size-l);
          color: white;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: var(--space-8);

      .btn {
        width: var(--space-22);
        height: var(--space-9);
        font-weight: normal;
      }

      .btnRegister {
        width: 100%;
        font-weight: normal;
      }

      h4 {
        cursor: pointer;
        font-size: var(--font-size-r);
        color: var(--color-blue-4);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .middleware {
    margin-top: var(--space-9);
    position: relative;
    z-index: 0;

    hr {
      border: 1px solid var(--color-accent-2);
    }

    span {
      z-index: 1;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -10px;
      font-size: var(--font-size-r);
      color: var(--color-light);
      padding: 0 var(--space-8);
      background: var(--color-main);
    }
  }

  .withGoogle {
    position: relative;

    .btnGoogle {
      width: 100%;
      color: var(--color-black);
      font-weight: var(--font-weight-medium);
      margin-top: var(--space-9);
    }

    .iconGoogle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: var(--space-8);
    }
  }
}

@media screen and(max-width: 600px) {
  .wrapper {
    .content {
      width: 98%;
    }
  ;
  }
}