html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

body * {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: var(--font-weight-regular);
  color: var(--color-text);
}

a {
  text-decoration: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

/* TYPOGRAPHY */
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.container {
  width: calc(1920px - (16rem * 2));
  margin: 0 auto;
}

@media screen and (max-width: 1440px) {
  .container {
    //width: calc(100% - (var(--space-8) * 2));
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .container {
    width: calc(100% - (var(--space-6) * 2));
  }
}