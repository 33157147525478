.wrapper {
  padding-top: var(--space-12);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  .itemWrapper {
    width: 45%;
    text-align: left;
    position: relative;
    margin-bottom: var(--space-9);
    cursor: pointer;

    span {
      position: absolute;
      top: 0;
      left: -40px;
      transform: rotate(-90deg);
      font-size: var(--font-size-xxxl);
      color: var(--color-opacity);
      font-weight: var(--font-weight-bold);
    }

    .content {
      width: 100%;
      margin-left: var(--space-2-5);
      padding: var(--space-5);
      background: var(--color-opacity-3);
      border: 2px solid var(--color-accent-2);
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        border: 2px solid var(--color-hover-2);
        box-shadow: 0 16px 30px rgba(65, 139, 231, 0.1);
      }

      h4 {
        font-size: var(--font-size-l);
        color: var(--color-light)
      }

      p {
        margin-top: var(--space-4);
        font-size: var(font-size-r);
      }
    }

    &.active {
      span {
        color: var(--color-blue-4)
      }

      .content {
        h4 {
          color: var(--color-dark-2);
        }
      }
    }
  }
}

@media screen and(max-width: 1000px) {
  .wrapper {
    flex-direction: column;

    .itemWrapper {
      width: calc(100% - var(--space-5));
    }
  }
}

@media screen and(max-width: 600px) {
  .wrapper {
    .itemWrapper {
      span {
        top: var(--space-5);
        left: var(--space-4);
      }

      .content {
        h4 {
          margin-left: var(--space-5);
        }
      }
    }
  }
}