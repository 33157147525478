:root {
  /* Spaces */
  --space-1: 0.2rem;
  --space-2: 0.5rem;
  --space-2-5: 0.625rem;
  --space-3: 0.75rem;
  --space-4: 1rem;
  --space-5: 1.3rem;
  --space-6: 1.5rem;
  --space-7: 1.75rem;
  --space-8: 2rem;
  --space-9: 2.5rem;
  --space-12: 3rem;
  --space-13: 3.5rem;
  --space-16: 4rem;
  --space-17: 4.5rem;
  --space-18: 5rem;
  --space-19: 5.75rem;
  --space-20: 7rem;
  --space-22: 8.25rem;
  --space-23: 8.75rem;
  --space-24: 9rem;
  --space-25: 10rem;
  --space-28: 13rem;

  /* Font-size */
  --font-size-root: 1rem;
  --font-size-xs: 0.5em;
  --font-size-s: 0.75em;
  --font-size-m: 0.875em;
  --font-size-m-r: 0.9375em;
  --font-size-r: 1.125em;
  --font-size-l: 1.3em;
  --font-size-xl: 1.5em;
  --font-size-xxl: 1.625em;
  --font-size-xxxl: 2.25em;
  --font-size-xxxxl: 3.75em;
  --font-size-xxxxxl: 4.5em;
  --font-size-xxxxxxl: 6.25em;

  /* Font weight */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  /* Main colors */
  --color-main: #FFFFFF;
  --color-title: #FF6C1A;
  --color-text: #464646;
  --color-light: #8A9CBF;
  --color-menu: #596D8C;
  --color-grey: rgba(114, 138, 182, 0.7);
  --color-hover: #728AB6;
  --color-hover-2: #C4E5FF;
  --color-white: #DAEBF8;
  --color-opacity: #DFF1FF;
  --color-opacity-2: #C2E5FF;
  --color-opacity-3: #FCFDFF;
  --color-opacity-4: #C5E6FF;
  --color-accent: #F9FCFF;
  --color-accent-2: #D3EBFE;
  --color-accent-3: #8d90fc;
  --color-blue: #4399E8;
  --color-blue-2: #5475AA;
  --color-blue-3: #395586;
  --color-blue-4: #449DE9;
  --color-blue-5: #556887;
  --color-blue-6: #4085E6;
  --color-dark: #2B466B;
  --color-dark-1: #242424;
  --color-dark-2: #36507D;
  --color-black: #000000;
  --color-footer: #253755;


  /* Main gradient */
  --main-gradient: linear-gradient(95.8deg, #49B8ED -10.63%, #3E7BE5 110.63%), #C4C4C4;

  /* Sizes */
  --header-height: 5rem;

  /* Radius */
  --logo-radius: 16px;
  --button-radius: 10px;
  --input-radius: 10px;
  --textarea-radius: 4px;
  --checkbox-radius: 24px;

  /* Shadow */
  --box-shadow: 0px 20px 30px rgba(65, 139, 231, 0.17);
}