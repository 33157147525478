.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: var(--space-20);

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 1;

    input {
      background: none;
      border: none;
      border-bottom: 1px solid var(--color-hover);
      outline: none;
      font-size: var(--font-size-l);
      color: var(--color-main);
      margin-bottom: var(--space-9);
      padding-bottom: var(--space-2);

      &::placeholder {
        color: var(--color-main);
      }
    }

    .fullName {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--space-5);

      input {
        width: 45%;
      }
    }

    .contact {
      display: flex;
      justify-content: space-between;

      input {
        width: 45%;
      }
    }
  }

  p {
    margin-bottom: var(--space-3);
    color: var(--color-hover);
    font-size: var(--font-size-m);
    text-align: justify;
  }

  .btn {
    width: 100%;
    margin-top: var(--space-3);
    margin-bottom: var(--space-20);
  }

  .successPage {
    position: absolute;
    opacity: 0;
    z-index: 0;

    &.activeSuccess {
      left: 60%;
      opacity: 2;
      transition-duration: 600ms;
      height: 500px;
    }
  }
}

@media screen and(max-width: 1600px) {
  .wrapper {
    .successPage {
      &.activeSuccess {
        left: 50%;
      }
    }
  }
}

@media screen and (max-width: 1000px){
  .wrapper {
    .successPage {
      &.activeSuccess {
        left: 50%;
        transform: translateX(-50%);
        height: 300px;
      }
    }
  }
}

@media screen and(max-width: 650px) {
  .wrapper {
    form {
      .fullName, .contact {
        flex-direction: column;

        input {
          width: 100%;
        }
      }
    }
  }
}