.wrapper {
  padding-bottom: var(--space-19);
  width: 100%;
  display: flex;
  justify-content: center;

  .container {
    width: 90%;
    display: flex;
    position: relative;
  }
}

.item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 7.5rem;
  text-align: center;
  margin: 0 var(--space-7);

  .icon {
    width: 7.5rem;
    height: var(--space-20);
    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px solid rgba(211, 235, 254, 0.6);
    box-sizing: border-box;
    border-radius: 3px;
  }

  span {
    margin-top: var(--space-5);
    font-size: var(--font-size-r);
  }
}

.btn {
  background: none;
  cursor: pointer;
  outline: none;

  .leftArrow, .rightArrow {
    font-size: var(--font-size-xxl);
    position: absolute;
    top: 120%;
    color: var(--color-blue);
    font-weight: var(--font-weight-bold);

    &:hover {
      color: #2070D7;
    }

    &:focus {
      background: none;
    }
  }

  .leftArrow {
    left: 48%;
  }

  .rightArrow {
    right: 48%;
  }
}

@media screen and(max-width: 1500px) {
  .btn {
    .leftArrow {
      left: 47%;
    }

    .rightArrow {
      right: 47%;
    }
  }
}

