.wrapper {
  border-bottom: 1px solid var(--color-hover);
  font-size: var(--font-size-l);
  margin-bottom: var(--space-9);
  padding-bottom: var(--space-2);
  position: relative;
  cursor: pointer;

  .title {
    text-align: left;
    color: var(--color-main);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .placeholder {
      font-size: var(--font-size-l);
      color: #ffffff;
      margin: 0;
    }

    span {
      font-size: var(--font-size-l);
      font-weight: var(--font-weight-semibold);
      color: var(--color-blue-2)
    }
  }

  .content {
    visibility: hidden;
    position: absolute;
    opacity: 0;
    width: 22.5rem;
    max-height: 20rem;
    z-index: 1;
    transform: perspective(600px) rotateX(-90deg);
    transform-origin: 0% 0%;
    transition: .3s ease-in-out;
    top: 0;
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: linear-gradient(143.42deg, #49B8ED 17.55%, #3E7BE5 77.75%);
    box-shadow: 0 20px 30px rgba(16, 35, 60, 0.24);
    overflow: auto;
    margin-bottom: var(--space-2);

    &.active {
      visibility: visible;
      opacity: 1;
      transform: perspective(600px) rotateX(0deg);
    }

    p {
      margin: var(--space-2-5) 0;
      font-size: var(--font-size-r);
      color: #FFFFFF;
      padding-left: var(--space-5);
    }

    .item {
      text-align: left;
      padding: 0.3rem var(--space-5);
      width: 100%;
      position: relative;

      span {
        font-size: var(--font-size-r);
        font-weight: var(--font-weight-medium);
        color: #FFFFFF;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.2);

        ::before {
          content: '+';
          position: absolute;
          right: var(--space-2);
        }
      }
    }
  }
}