.wrapper {
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
  width: 27rem;
  opacity: 0;
  background: linear-gradient(235.98deg, #FFFFFF 1.01%, #FFFFFF 64.7%, #ECF7FF 139.96%), #FCFDFF;
  border: 2px solid var(--color-accent-2);
  box-sizing: border-box;
  box-shadow: 0 16px 30px rgba(65, 139, 231, 0.15);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition-duration: 400ms;

  h3, h6 {
    text-align: center;
  }

  &.active {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 2;
    transition-duration: 400ms;
  }

  h3 {
    max-width: 18rem;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-l);
    color: var(--color-footer);
    padding-top: var(--space-7);
  }

  .text {
    padding-top: var(--space-7);
    display: flex;
    justify-content: center;
    font-size: var(--font-size-r);

    span {
      color: var(--color-blue-4);
      cursor: pointer;
    }
  }

  .emailDescription {
    margin-top: var(--space-2);
    font-size: var(--font-size-r);
    text-align: center;
    max-width: 18rem;
  }

  h6 {
    cursor: pointer;
    padding-top: var(--space-8);
    margin-bottom: var(--space-3);
    display: inline-block;
    font-size: var(--font-size-m);
    color: var(--color-hover);
    border-bottom: 1px dashed var(--color-hover);
  }

  .form {
    min-width: 75%;

    .input {
      margin-top: var(--space-6);
      margin-bottom: var(--space-3);
      height: var(--space-9);
      width: 100%;
      background: var(--color-main);
      border: 1px solid var(--color-opacity-4);
      box-sizing: border-box;
      border-radius: var(--textarea-radius);
      font-size: var(--font-size-r);
      padding-left: var(--space-6);
      color: var(--color-blue-4);
      text-align: center;
      outline: none;
      cursor: pointer;

      &:hover {
        border: 1px solid var(--color-blue-4);
      }

      &:focus {
        border: 1px solid var(--color-blue-4);
      }

      &::placeholder {
        color: var(--color-blue-5);
        text-align: left;
      }

      &:disabled {
        background: #c1cad9;
        border: 1px solid var(--color-blue-4);
      }
    }

    p {
      max-width: 18rem;
      color: #FF431A;
    }
  }

  .btn {
    margin-top: var(--space-4);
    width: var(--space-22);
    height: var(--space-9);
    font-weight: normal;
  }

  .close {
    position: absolute;
    top: var(--space-2);
    right: var(--space-2);
    cursor: pointer;
  }

  .textReset {
    font-size: var(--font-size-m-r);
    margin: 0;
    padding: var(--space-2-5) 0 var(--space-8);
    text-align: center;
    max-width: 18rem;

    span {
      color: #449de9;
      cursor: pointer;
    }

    .reset {
      margin-top: var(--space-6);

      span {
        text-decoration: underline;
      }
    }
  }
}

@media screen and(max-width: 600px) {
  .wrapper {
    width: 90%;
  }
}