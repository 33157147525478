.footer {
  border-top: 4px solid #49B8ED;
  min-height: 80px;
  background: var(--color-footer);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: var(--color-white);
      margin: 0 var(--space-2-5);
    }
  }
}

@media screen and(max-width: 900px) {
  .footer {
    .content {
      flex-direction: column-reverse;

      .left {
        margin: var(--space-5) 0;
      }

      .right {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: var(--space-2-5);
      }
    }
  }
}