.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(95.8deg, #49B8ED -10.63%, #3E7BE5 110.63%), #253755;
  padding: var(--space-12) 0;
  font-family: 'Roboto Condensed', sans-serif;

  h2 {
    color: var(--color-main);
    font-size: var(--font-size-xxxl);
    text-transform: uppercase;
    font-weight: var(--font-weight-semibold);
  }

  p {
    margin-top: var(--space-6);
    color: #ffffff;
    font-size: var(--font-size-xxl);
    font-weight: var(--font-weight-medium);
  }

  .btn {
    margin-top: var(--space-9);
  }
}

@media screen and(max-width: 650px) {
  .wrapper {
    h2 {
      font-size: var(--font-size-xxl);
    }

    p {
      font-size: var(--font-size-xl);
    }
  }
}

@media screen and(max-width: 450px) {
  .wrapper {
    h2 {
      font-size: var(--font-size-l);
    }

    p {
      font-size: var(--font-size-r);
    }
  }
}