.wrapper {
  margin-top: var(--space-16);

  h2 {
    font-size: 6.25em;
    text-align: left;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
  }

  .title {
    color: var(--color-dark);
  }

  .word {
    color: var(--color-dark);
    font-size: 6.25em;
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    text-align: left;
  }

  h4 {
    text-align: left;
    font-size: var(--font-size-l);
    color: var(--color-main);
    margin-top: var(--space-8);
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {
    .title {
      text-align: center;
      font-size: 3.3em;
    }

    .word {
      font-size: var(--font-size-xxxxxl);
      text-align: center;
    }

    h4 {
      text-align: center;
    }
  }
}