.wrapper {
  width: 100%;
  min-height: 100vh;

  h1 {
    font-size: var(--font-size-xxxl);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    font-family: 'Roboto Condensed', sans-serif;
    background: radial-gradient(50% 50%, #49B8ED, #3E7BE5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media screen and(max-width: 850px) {
  .wrapper {
    min-height: 100%;
  }
}