.wrapper {
  margin-top: var(--space-8);
  padding-bottom: var(--space-8);
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: var(--font-size-xxxl);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    font-family: 'Roboto Condensed', sans-serif;
    background: radial-gradient(50% 50%, #49B8ED, #3E7BE5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: space-between;

    h4 {
      color: var(--color-main);
      cursor: pointer;
      text-transform: uppercase;
      font-size: var(--font-size-xl);
      font-family: 'Roboto', sans-serif;

      &.active {
        color: var(--color-title);
        transition-duration: 600ms;
      }
    }
  }

  .mazeContent {
    position: relative;
    margin-top: var(--space-8);

    .mazeImage {
      width: 100%;
    }

    .wordTask {
      position: absolute;
      transform: rotate(-90deg);
      color: var(--color-blue-3);
      font-size: var(--font-size-xl);
      left: -45px;
      top: 55px;
      text-transform: uppercase;
    }

    .wordPurpose {
      position: absolute;
      transform: rotate(-90deg);
      color: var(--color-blue-3);
      font-size: var(--font-size-xxl);
      top: 70%;
      right: -90px;
      text-transform: uppercase;
    }
  }
}

@media screen and(max-width: 1200px) {
  .wrapper {
    .menu {
      flex-direction: column;
      align-items: flex-start;

      h4 {
        margin-bottom: var(--space-6);
        font-weight: var(--font-weight-semibold);
        text-align: left;
      }
    }

    .mazeContent {
      margin-top: var(--space-4);
    }
  }
}

@media screen and(max-width: 550px) {
  .wrapper {
    .mazeContent {
      overflow: hidden;
    }
  }
}