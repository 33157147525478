.wrapper {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  background-image: url("../MainLayout/images/background-main.png");

  &.forgotPassword {
    background: #c1cad9;
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}